'use client'

import { useState, useTransition } from 'react'
import LanguageIcon from '@mui/icons-material/Language'
import { alpha, Box, IconButton, Menu, MenuItem, useTheme } from '@mui/material'

import { useLocale, useTranslations } from 'next-intl'

import { locales } from '@lib/constants'
import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

import { useMuiThemeContext } from 'contextProviders/MuiThemeContextProvider/MuiThemeContextProviderClient'
import { supportedLocales } from 'contextProviders/MuiThemeContextProvider/SupportedLocales'

const { useRouter, usePathname } = getLivSharedPathnamesNavigation()

function LanguageSwitcher(): React.JSX.Element {
  const t = useTranslations('LanguageSwitcher')
  const theme = useTheme()
  const [, startTransition] = useTransition()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const locale = useLocale()
  const router = useRouter()
  const pathname = usePathname()
  const muiThemeContext = useMuiThemeContext()

  function onLanguageChange(nextLocale: string) {
    return () => {
      startTransition(() => {
        const selectedLocale =
          supportedLocales.find((loc) => loc.code === nextLocale) ??
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We know that the first element exists
          supportedLocales[0]!
        muiThemeContext.setLocale(selectedLocale)
        router.replace(pathname, { locale: nextLocale })
      })
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box display="flex" marginLeft="auto" marginX={1}>
        <IconButton
          onClick={handleClick}
          aria-controls={open ? 'language-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          aria-label="Language switcher"
          sx={{
            '&:hover': {
              color: 'primary.main',
            },
          }}
        >
          <LanguageIcon />
        </IconButton>
      </Box>
      <Menu
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          '.MuiPaper-root': {
            padding: 1,
            marginTop: 1,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 8,
            borderBottomLeftRadius: 8,
            borderTop: `3px solid ${theme.palette.primary.main}`,
          },
          justifyContent: 'flex-start',
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          },
        }}
      >
        {locales.map((cur) => (
          <MenuItem
            key={cur}
            onClick={onLanguageChange(cur)}
            disabled={locale === cur}
            sx={{
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.1),
              },
            }}
          >
            {/* @ts-expect-error cur is a dynamic value */}
            {t(cur)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSwitcher

import type { PaletteMode } from '@mui/material'
import type { PaletteOptions } from '@mui/material/styles/createPalette'

export const light: PaletteOptions = {
  cardShadow: 'rgba(23, 70, 161, .11)',
  mode: 'light' as PaletteMode,
  primary: {
    main: '#01BA16',
    light: '#01BA1614',
    dark: '#0E7F15',
    contrastText: '#fff',
  },
  secondary: {
    main: '#25A9BE',
    light: '#7CCBD8',
    dark: '#1C7B7F',
    contrastText: 'rgba(255, 255, 255, 0.87)',
  },
  error: {
    main: '#EE2424',
    light: '#EE242414',
    dark: '#CC1C1E',
    contrastText: '#fff',
  },
  warning: {
    main: '#FDBE17',
    light: '#FDBE1729',
    dark: '#E3AA15',
    contrastText: '#fff',
  },
  info: {
    main: '#48ccc1',
    light: '#91E1DE2A',
    dark: '#469991',
    contrastText: '#fff',
  },
  success: {
    main: '#A3D824',
    light: '#CDEC77',
    dark: '#4F8C0D',
    contrastText: '#fff',
  },
  text: {
    primary: '#000',
    secondary: '#00000099',
    disabled: '#00000061',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#ffffff',
    default: '#eff4f5',
  },
  common: {
    white: '#fff',
  },
}

'use client'

import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useRef, useState } from 'react'
import {
  AppBar,
  Box,
  Container,
  useScrollTrigger,
  useTheme,
} from '@mui/material'

import { useIsMobile } from '@liv-frontend/ui'

import Sidebar from './_components/Sidebar'
import Topbar from './_components/Topbar'
import pages from './navigation'

export type HeaderClientProps = {
  isLoggedIn: boolean
  setHeight?: Dispatch<SetStateAction<number>>
}

function Header({
  isLoggedIn,
  setHeight,
}: Readonly<HeaderClientProps>): React.JSX.Element {
  const theme = useTheme()
  const isMobile = useIsMobile()
  const ref = useRef<HTMLElement | null>(null)

  const [openSidebar, setOpenSidebar] = useState(false)

  const handleSidebarOpen = (): void => {
    setOpenSidebar(true)
  }

  const handleSidebarClose = (): void => {
    setOpenSidebar(false)
  }

  const open = isMobile ? openSidebar : false

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  })

  useEffect(() => {
    if (ref.current && setHeight) {
      setHeight(ref.current.offsetHeight)
    }
  }, [setHeight])

  return (
    <>
      <AppBar
        ref={ref}
        position="sticky"
        sx={{
          top: 0,
          // This background is the one that sets the appbar color
          backgroundColor: trigger ? 'rgba(255, 255, 255, 1)' : 'transparent',
          borderBottom: trigger ? '' : `1px solid ${theme.palette.divider}`,
        }}
        elevation={trigger ? 1 : 0}
      >
        <Container>
          <Box paddingY={1}>
            <Topbar
              onSidebarOpen={handleSidebarOpen}
              isLoggedIn={isLoggedIn}
              pages={pages}
            />
          </Box>
        </Container>
      </AppBar>
      {!isLoggedIn ? (
        <Container>
          <Sidebar
            onClose={handleSidebarClose}
            open={open}
            variant="temporary"
            pages={pages}
          />
        </Container>
      ) : null}
    </>
  )
}

export default Header

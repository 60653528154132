import React from 'react'
import { useAuth } from '@clerk/nextjs'

import Header from '@layout/Header'

export type MainLayoutProps = {
  children: React.ReactNode
}

function MainLayout({ children }: MainLayoutProps) {
  const { userId } = useAuth()

  return (
    <>
      <Header isLoggedIn={Boolean(userId)} />
      {children}
    </>
  )
}

export default MainLayout

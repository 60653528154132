'use client'

import type { Dispatch, SetStateAction } from 'react'
import { createContext, useContext, useMemo, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { useLocale } from 'next-intl'

import NextAppDirEmotionCacheProvider from 'theme/EmotionCache'
import ThemeRegistry from 'theme/ThemeRegistry'

import 'dayjs/locale/es-mx'

import type { MUILocaleData } from './SupportedLocales'
import { supportedLocales } from './SupportedLocales'

type MuiThemeContextType = {
  locale: MUILocaleData
  setLocale: Dispatch<SetStateAction<MUILocaleData>>
}

export const MuiThemeContext = createContext<MuiThemeContextType>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We know that the first locale is supported
  locale: supportedLocales[0]!,
  setLocale: () => undefined,
})

MuiThemeContext.displayName = 'MuiThemeContext'
type MuiThemeContextProviderClientProps = {
  children: React.ReactNode
}

export function useMuiThemeContext() {
  return useContext(MuiThemeContext)
}

function MuiThemeContextProviderClient(
  props: MuiThemeContextProviderClientProps,
) {
  const initialLocale = useLocale()
  const supportedLocale = supportedLocales.filter(
    (locale) => locale.code === initialLocale,
  )[0]

  const { children } = props
  const [locale, setLocale] = useState<MUILocaleData>(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- We know that the first locale is supported
    supportedLocale ?? supportedLocales[0]!,
  )

  const contextValue = useMemo(
    () => ({ locale, setLocale }),
    [locale, setLocale],
  )

  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'mui' }}>
      <MuiThemeContext.Provider value={contextValue}>
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={locale.dayJSLanguage}
        >
          <ThemeRegistry>{children}</ThemeRegistry>
        </LocalizationProvider>
      </MuiThemeContext.Provider>
    </NextAppDirEmotionCacheProvider>
  )
}

export default MuiThemeContextProviderClient

import { useQuery } from '@tanstack/react-query'

import { getInvestigationsForMeAsInterestedParty } from '@lib/apiFunctions'

import { useLivUserContext } from 'contextProviders/LivUserContextProvider/LivUserProviderContextProviderClient'

const REFRESH_INTERVAL = 2.5 * 60 * 1000

const useInvestigationsForMeAsInterestedParty = () => {
  const { livUserUUID } = useLivUserContext()
  const { data, isPending } = useQuery({
    queryKey: ['investigationsForMeAsInterestedParty'],
    queryFn: getInvestigationsForMeAsInterestedParty,
    enabled: livUserUUID !== null,
    staleTime: REFRESH_INTERVAL,
  })

  return { investigationsForMeAsInterestedParty: data?.items, isPending }
}

export default useInvestigationsForMeAsInterestedParty

import MenuIcon from '@mui/icons-material/Menu'
import { AvatarGroup, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LinkMui from '@mui/material/Link'
import LanguageSwitcher from '@UI/sharedComponents/LanguageSwitcher'
import LogoText from '@UI/sharedComponents/LogoText'
import { useRentOperation } from 'app/[locale]/legal-policies/rentOperationProvider'

import { useTranslations } from 'next-intl'

import { useIsMobile } from '@liv-frontend/ui'

import type { LivRentOperationCollaboratorPOGetResponse } from '@api-client/livBackend.schemas'
import UserAvatar from '@components/UserAvatar'
import UserAvatarMenu from '@components/UserAvatarMenu'
import type { NavigationElement } from '@layout/Header/navigation'
import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

import { useLivUserContext } from 'contextProviders/LivUserContextProvider/LivUserProviderContextProviderClient'

import NavItem from './_components/NavItem'

const { Link, usePathname } = getLivSharedPathnamesNavigation()

type Props = {
  onSidebarOpen: () => void
  isLoggedIn: boolean
  pages: NavigationElement[]
  collaborators?: LivRentOperationCollaboratorPOGetResponse[]
}

const MAX_COLLABORATORS = 3

function Topbar({
  onSidebarOpen,
  isLoggedIn,
  pages = [],
}: Readonly<Props>): React.JSX.Element {
  const t = useTranslations('Menu')
  const pathname = usePathname()
  const isMobile = useIsMobile()
  const { rentOperation } = useRentOperation()
  const { livUserUUID } = useLivUserContext()

  const collaborators = rentOperation?.rent_operation_collaborator
  const isCreator = rentOperation?.created_by_user.uuid === livUserUUID

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={1}
    >
      <Box display="flex">
        {!isLoggedIn ? (
          <Button
            onClick={() => onSidebarOpen()}
            aria-label="Menu"
            variant="outlined"
            sx={{
              minWidth: 'auto',
              padding: 0,
              border: 'none',
              display: { md: 'none' },
              marginRight: 1,
              color: 'black',
              '&:hover': {
                border: 'none',
              },
            }}
          >
            <MenuIcon />
          </Button>
        ) : null}
        <Box display="flex">
          <LinkMui color="primary" underline="none" component={Link} href="/">
            <LogoText variant="h3" component="p" />
          </LinkMui>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {!isMobile &&
            !isLoggedIn && // Hide top menu on mobile or if isLoggedIn
            pages.map((p) => (
              <Box key={p.id} marginLeft={3}>
                {!p.option.children ? (
                  <LinkMui
                    color="text.primary"
                    component={Link}
                    href={p.option.href}
                    target={p.option.target}
                    sx={{
                      '&:hover': {
                        color: 'primary.main',
                      },
                      textDecoration:
                        pathname === p.option.href ? 'underline' : 'none',
                      textUnderlineOffset: 5,
                    }}
                  >
                    {/* @ts-expect-error titles come from navigation.ts */}
                    {t(p.title)}
                    {p.isNew ? (
                      <Box
                        padding={0.5}
                        display="inline-flex"
                        borderRadius={1}
                        bgcolor="primary.main"
                        marginLeft={1}
                      >
                        <Typography
                          variant="caption"
                          sx={{ color: 'common.white', lineHeight: 1 }}
                        >
                          {t('New')}
                        </Typography>
                      </Box>
                    ) : null}
                  </LinkMui>
                ) : (
                  <NavItem
                    title={p.title}
                    items={p.option.children}
                    id={p.id}
                  />
                )}
              </Box>
            ))}
        </Box>
      </Box>
      <Box sx={{ display: 'flex' }} alignItems="center">
        <Stack direction="row" display="flex" alignItems="center">
          {isCreator && collaborators ? (
            <LinkMui
              component={Link}
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- Disabling rule because it'll be defined if enters to this block
              href={`/legal-policies/invite-collaborator/${collaborators[0]?.rent_operation_uuid}`}
              underline="none"
            >
              <AvatarGroup
                max={MAX_COLLABORATORS}
                slotProps={{
                  additionalAvatar: {
                    sx: {
                      width: 32,
                      height: 32,
                      fontSize: '.8rem',
                      color: 'black',
                    },
                  },
                }}
              >
                {collaborators.map(
                  (collaborator: LivRentOperationCollaboratorPOGetResponse) => (
                    <UserAvatar
                      key={collaborator.uuid}
                      username={`${collaborator.contact_info_added_by_other_user.names} ${collaborator.contact_info_added_by_other_user.last_names}`}
                    />
                  ),
                )}
              </AvatarGroup>
            </LinkMui>
          ) : null}

          <LanguageSwitcher />
          {isLoggedIn ? (
            <UserAvatarMenu />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                gap: 1,
              }}
            >
              <Button
                component={Link}
                color="primary"
                variant="contained"
                href="/user/sign-up"
                sx={{ textTransform: 'none' }}
              >
                {t('SignUp')}
              </Button>
              <Button
                component={Link}
                color="primary"
                variant="outlined"
                href="/user/sign-in"
                sx={{ textTransform: 'none' }}
              >
                {t('SignIn')}
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default Topbar

'use client'

import { cloneElement, useState } from 'react'
import { useClerk } from '@clerk/nextjs'
import { Close, Home, Person } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import { useAnalytics } from 'use-analytics'
import { AnalyticsEvents } from 'web-constants/analyticsEvents'

import { useTranslations } from 'next-intl'

import { Icon } from '@liv-frontend/ui'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

import {
  useLivUserContext,
  UserType,
} from 'contextProviders/LivUserContextProvider/LivUserProviderContextProviderClient'
import useInvestigationsCreatedByMe from 'hooks/investigations/useInvestigationsCreatedByMe'
import useInvestigationsForMeAsInterestedParty from 'hooks/investigations/useInvestigationsForMeAsInterestedParty'
import useInvestigationsForMeAsInvestigatedSubject from 'hooks/investigations/useInvestigationsForMeAsInvestigatedSubject'

import LogoText from '../../UI/sharedComponents/LogoText'
import UserAvatar from '../UserAvatar'

const { useRouter } = getLivSharedPathnamesNavigation()

function UserAvatarMenu(): React.JSX.Element {
  const { investigationsForMeAsInvestigatedSubject } =
    useInvestigationsForMeAsInvestigatedSubject()
  const { investigationsCreatedByMe } = useInvestigationsCreatedByMe()
  const { investigationsForMeAsInterestedParty } =
    useInvestigationsForMeAsInterestedParty()
  const t = useTranslations('UserAvatarMenu')
  const theme = useTheme()
  const clerk = useClerk()
  const router = useRouter()
  const { userType, setUserType } = useLivUserContext()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { track } = useAnalytics()

  const handleOpen = (): void => setIsDialogOpen(true)
  const handleClose = (): void => setIsDialogOpen(false)

  const profiles = [
    {
      type: UserType.Agent,
      icon: <Icon.Agent2 />,
      primary: t('Agent'),
      secondary: t(
        investigationsCreatedByMe?.length === 1 ? 'Operation' : 'Operations',
        { numOfOperations: investigationsCreatedByMe?.length },
      ),
      link: () => {
        handleClose()
        void track(AnalyticsEvents.UserProfileChanged, {
          userType: UserType.Agent,
        })
        setUserType(UserType.Agent)
        router.push('/control-panel')
      },
    },
    {
      type: UserType.Owner,
      icon: <Home />,
      primary: t('Owner'),
      secondary: t(
        investigationsForMeAsInterestedParty?.length === 1
          ? 'Operation'
          : 'Operations',
        {
          numOfOperations:
            (investigationsCreatedByMe?.length ?? 0) +
            (investigationsForMeAsInterestedParty?.length ?? 0),
        },
      ),
      link: () => {
        handleClose()
        void track(AnalyticsEvents.UserProfileChanged, {
          userType: UserType.Owner,
        })
        setUserType(UserType.Owner)
        router.push('/owners')
      },
    },
    {
      type: UserType.Tenant,
      icon: <Person />,
      primary: t('Tenant'),
      secondary: t(
        investigationsForMeAsInvestigatedSubject?.length === 1
          ? 'Operation'
          : 'Operations',
        { numOfOperations: investigationsForMeAsInvestigatedSubject?.length },
      ),
      link: () => {
        handleClose()
        void track(AnalyticsEvents.UserProfileChanged, {
          userType: UserType.Tenant,
        })
        setUserType(UserType.Tenant)
        router.push('/my-investigations')
      },
    },
  ]

  const handleSignOutClick = (): void => {
    handleClose()
    void (async () => {
      await clerk.signOut()
      router.push('/info')
    })()
  }

  const selectedProfile = profiles.find((profile) => profile.type === userType)

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        padding={0}
      >
        <IconButton
          onClick={handleOpen}
          sx={{
            p: '4px',
            border: '2px solid',
            borderColor: 'primary.main',
          }}
        >
          <UserAvatar />
        </IconButton>
      </Box>

      <Dialog
        onClose={handleClose}
        aria-labelledby="liv-user-menu"
        open={isDialogOpen}
        sx={{
          '& .MuiPaper-root': {
            width: '344px',
            margin: 0,
          },
          borderRadius: 16,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: theme.palette.grey[500],
          }}
          onClick={handleClose}
        >
          <Close sx={{ mt: '4px' }} />
        </IconButton>
        <DialogTitle
          sx={{
            m: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ flexGrow: 1, textAlign: 'center' }}>
            <LogoText variant="h4" component="p" />
          </div>
        </DialogTitle>
        {selectedProfile ? (
          <>
            <DialogContent sx={{ pb: 0 }}>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {t('ProfileSelected')}
              </Typography>
              <List sx={{ width: '100%', p: 0 }}>
                <ListItemButton
                  onClick={selectedProfile.link}
                  sx={{ pl: 0, pr: 0, display: 'flex', alignItems: 'center' }}
                >
                  <ListItemAvatar
                    sx={{
                      width: '33px',
                      height: '100%',
                      minWidth: '33px',
                      marginRight: 1.5,
                    }}
                  >
                    <Avatar
                      sx={{
                        backgroundColor: theme.palette.common.white,
                        border: `1px solid ${theme.palette.primary.main}`,
                        color: theme.palette.primary.main,
                      }}
                    >
                      {cloneElement(selectedProfile.icon, {
                        color: theme.palette.primary.main,
                      })}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body2"
                        sx={{ color: theme.palette.primary.main }}
                      >
                        {selectedProfile.primary}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="caption"
                        sx={{ color: theme.palette.text.disabled }}
                      >
                        {selectedProfile.secondary}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </List>

              <Divider sx={{ marginBottom: 2 }} />

              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {t('ChangeProfile')}
              </Typography>

              <List sx={{ width: '100%', p: 0 }}>
                {profiles
                  .filter((profile) => profile.type !== userType)
                  .map((item) => (
                    <ListItemButton
                      key={item.primary}
                      onClick={item.link}
                      sx={{
                        pl: 0,
                        pr: 0,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ListItemAvatar
                        sx={{
                          width: '33px',
                          height: '100%',
                          minWidth: '33px',
                          marginRight: 1.5,
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: theme.palette.common.white,
                            border: `1px solid ${theme.palette.secondary.main}`,
                            color: theme.palette.secondary.main,
                          }}
                        >
                          {cloneElement(item.icon, {
                            color: theme.palette.secondary.main,
                          })}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        sx={{ m: 0 }}
                        primary={
                          <Typography variant="body2" sx={{ lineHeight: 1 }}>
                            {item.primary}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="caption"
                            sx={{
                              color: theme.palette.text.disabled,
                              lineHeight: 1,
                            }}
                          >
                            {item.secondary}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  ))}
              </List>
            </DialogContent>
            <Divider sx={{ marginLeft: 2, marginRight: 2 }} />
          </>
        ) : null}

        <DialogActions>
          <Button onClick={handleSignOutClick}>{t('Logout')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default UserAvatarMenu

/* eslint-disable @typescript-eslint/no-unnecessary-condition -- some users don't have lastname */
import { Avatar } from '@mui/material'

import { useLivUserContext } from 'contextProviders/LivUserContextProvider/LivUserProviderContextProviderClient'

type Props = {
  width?: number
  height?: number
  username?: string
}

const DEFAULT_USERNAME = 'Liv Marketplace'

function stringToColor(string: string): string {
  let hash = 0
  let i

  /* eslint-disable no-bitwise -- doing bitwise on purpose */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise -- doing bitwise on purpose */

  return color
}

function UserAvatar({
  width = 32,
  height = 32,
  username = '',
}: Readonly<Props>): JSX.Element {
  const { user } = useLivUserContext()
  const userImageURL = user?.imageUrl ?? ''
  const name = username?.split(' ') ?? DEFAULT_USERNAME
  const initials = `${name[0]?.[0] ?? ''}${name[1]?.[0] ?? ''}`

  return (
    <Avatar
      variant="circular"
      src={username ? undefined : userImageURL}
      sx={{
        width,
        height,
        fontSize: '.8rem',
        bgcolor: stringToColor(name.toString() ?? DEFAULT_USERNAME),
      }}
    >
      {initials}
    </Avatar>
  )
}

export default UserAvatar

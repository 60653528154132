export type NavigationOption =
  | { href: string; children?: never; target?: string }
  | { href?: never; children: NavigationElement[] }

export type NavigationElement = {
  id: string
  title: string
  option: NavigationOption
  isNew?: boolean
}

const pages: NavigationElement[] = [
  {
    id: 'products-investigations',
    title: 'Investigations',
    option: {
      href: '/info/products/investigations/',
    },
  },
  {
    id: 'products-contracts',
    title: 'Contracts',
    option: {
      href: '/info/products/contracts/',
    },
    isNew: true,
  },
  {
    id: 'products-legal-policy',
    title: 'LegalPolicy',
    option: {
      href: '/info/products/legal-policy/',
    },
  },
  {
    id: 'help',
    title: 'Help',
    option: {
      href: 'https://help.liv.place/es/collections/4078942-preguntas-frecuentes',
      target: '_blank',
    },
    isNew: true,
  },
]

export default pages

import { useQuery } from '@tanstack/react-query'

import { getRentOperationByUUID } from '@lib/apiFunctions'

const useRentOperationByUUID = (uuid: string) => {
  const { data, isLoading, isRefetching } = useQuery({
    queryKey: ['getRentOperationByUUIDResult', uuid],
    queryFn: () => {
      return getRentOperationByUUID({ uuid })
    },
  })

  return { rentOperation: data, isLoading, isRefetching }
}

export default useRentOperationByUUID

/* eslint-disable prefer-named-capture-group -- Code from the internet */

export const TIMEZONE = 'America/Mexico_City'

export const YEAR_3000_IN_MILISECONDS = 32503705200000

export const FAKER_SEED = 230831

export const WhatsappURL = 'https://wa.me'

export const LivWhatsappNumber = '525530446390'

export const locales = ['es-MX', 'en']

export const PostHogBoostrapDataCookieKey = 'liv_phBootstrapData'

export const curpRegex =
  /^([A-Z&]|[a-z&]{1})([AEIOUX]|[aeioux]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})(AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne)([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([A-Z0-9]{2})$/

export const rfcRegEx =
  /[A-Za-zÑñ&]{3,4}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(?:[A-Za-z\d]{3})?/i

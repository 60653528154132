import React, { createContext, useContext, useMemo } from 'react'

import type { LivRentOperationPOGetResponse } from '@api-client/livBackend.schemas'

import useRentOperationByUUID from 'hooks/rentOperations/useRentOperationByUUID'

type ProviderProps = {
  uuid: string | null
  children: React.ReactNode
}

const RentOperationContext = createContext<{
  rentOperation?: LivRentOperationPOGetResponse
}>({})

export function RentOperationProvider({
  uuid,
  children,
}: ProviderProps): JSX.Element {
  const { rentOperation } = useRentOperationByUUID(uuid?.toString() ?? '')
  const value = useMemo(() => ({ rentOperation }), [rentOperation])

  return (
    <RentOperationContext.Provider value={value}>
      {children}
    </RentOperationContext.Provider>
  )
}

export function useRentOperation(): {
  rentOperation?: LivRentOperationPOGetResponse
} {
  const context = useContext(RentOperationContext)
  return context
}
